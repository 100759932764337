import React from 'react'
import PropTypes from 'prop-types'
import monogram from '../img/Q_monogram.svg'
import { globalHistory } from "@reach/router"
import { Link } from 'gatsby'



export default class TheQ extends React.Component {
    placeRandomly() {
        var posY = ['90%', '85%', '80%', '75%', '70%'];
        this.posXr = Math.random() * 90 + '%';
        this.posYr = posY[Math.floor(Math.random() * posY.length)];
    }
    listenToChange() {
        var posY = ['90%', '85%', '80%', '75%', '70%'];
        this.posXr = Math.random() * 90 + '%';
        this.posYr = posY[Math.floor(Math.random() * posY.length)];
        globalHistory.listen(({ location }) => {
            this.posXr = Math.random() * 90 + '%';
            this.posYr = posY[Math.floor(Math.random() * posY.length)];
        })
    }
    render() {
        return (
            <div style={{ top: this.posYr, left: this.posXr }} onClick={this.placeRandomly()} className="the-q has-text-centered is-vcentered">
                {this.listenToChange()}
                <Link to="/" style={{borderBottom: 'none'}}>
                    <img src={monogram} alt="Logo" />
                </Link>
            </div>
        )
    }
}

TheQ.propTypes = {
    posx: PropTypes.string,
    posy: PropTypes.string,
}